import React, { useEffect, useState, useMemo } from 'react';
import md from '../../config/mdRenderer';

import { useRouteMatch } from 'react-router-dom';
import AOS from 'aos';
import api from '../../services/api';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { format, parseISO } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import NotFound from '../NotFound';

import Instagram from '../../assets/img/icons/social/instagram.svg';
import Facebook from '../../assets/img/icons/social/facebook.svg';
import Linkedin from '../../assets/img/icons/social/linkedin.svg';

interface IParams {
  post: string;
}

interface IPost {
  id: number;
  titulo: string;
  descricao: string;
  created_at: string;
  conteudo: string;
  imagem: {
    url: string;
  };
  created_by: {
    id: number;
    firstname: string;
    lastname: string;
  };
}

const PostDetail: React.FC = () => {
  AOS.init();

  const { params } = useRouteMatch<IParams>();

  const [post, setPost] = useState<IPost>();
  const [loading, setLoading] = useState(true);
  const html = useMemo(() => md.render(post?.conteudo || ''), [post]);

  useEffect(() => {
    api.get(`/posts/${params.post}`).then(response => {
      setPost(response.data);
    });
  }, [params.post]);

  setTimeout(() => setLoading(false), 600);

  return (
    <>
      <Header />
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
          }}
        >
          <div className="spinner-border text-primary  " role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {post && !loading && (
        <>
          <section className="pt-8 pt-md-11">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-9 col-xl-8">
                  <h1 className="display-4 text-center">{post.titulo}</h1>

                  <p className="lead mb-7 text-center text-muted">
                    {post.descricao}
                  </p>

                  <div className="row align-items-center py-5 border-top border-bottom">
                    <div className="col-auto">
                      <div className="avatar avatar-lg">
                        <img
                          src="assets/img/avatars/avatar-1.jpg"
                          alt="..."
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="col ml-n5">
                      <h6 className="text-uppercase mb-0">
                        {post.created_by.firstname} {post.created_by.lastname}
                      </h6>

                      <time
                        className="font-size-sm text-muted"
                        dateTime="2019-05-20"
                      >
                        {format(
                          parseISO(post.created_at),
                          "dd 'de' MMMM 'de' yyyy",
                          {
                            locale: ptBr,
                          },
                        )}
                      </time>
                    </div>
                    <div className="col-auto">
                      <span className="h6 text-uppercase text-muted d-none d-md-inline mr-4">
                        Compartihe:
                      </span>

                      <ul className="d-inline list-unstyled list-inline list-social">
                        <li className="list-inline-item list-social-item mr-3">
                          <a href="/" className="text-decoration-none">
                            <img
                              src={Instagram}
                              className="list-social-icon"
                              alt="..."
                            />
                          </a>
                        </li>
                        <li className="list-inline-item list-social-item mr-3">
                          <a href="/" className="text-decoration-none">
                            <img
                              src={Facebook}
                              className="list-social-icon"
                              alt="..."
                            />
                          </a>
                        </li>
                        <li className="list-inline-item list-social-item mr-3">
                          <a href="/" className="text-decoration-none">
                            <img
                              src={Linkedin}
                              className="list-social-icon"
                              alt="..."
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="pt-6 pt-md-8">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-9 col-xl-8">
                  <figure className="figure mb-7">
                    <img
                      className="figure-img img-fluid rounded lift lift-lg"
                      src={`https://blogapi.financecores.com${post.imagem.url}`}
                      alt="..."
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="pt-6 pt-md-8">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-11 col-xl-10">
                  <div dangerouslySetInnerHTML={{ __html: html }} />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {!loading && !post && <NotFound />}
      <Footer />
    </>
  );
};

export default PostDetail;
