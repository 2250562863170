import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../services/api';

interface ICategory {
  id: number;
  nome: string;
  created_at: Date;
  created_by: {
    id: number;
    firstname: string;
    lastname: string;
  };
}

const Header: React.FC = () => {
  const [categories, setCategories] = useState<ICategory[]>([]);

  useEffect(() => {
    api.get('/categories').then(response => {
      setCategories(response.data);
    });
  }, []);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-white"
      style={{ marginTop: 20 }}
    >
      <div className="container">
        <Link className="navbar -brand" to="/" style={{ marginRight: 40 }}>
          <img
            src="./assets/img/brand.svg"
            className="navbar-brand-img"
            alt="..."
          />
        </Link>
        <button
          type="button"
          className=" btn btn-sm btn-primary lift ml-auto"
          style={{
            padding: 5,
            fontSize: 10,
            width: 54,
            cursor: 'default',
          }}
        >
          BLOG
        </button>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarCollapse">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fe fe-x" />
          </button>

          <ul className="navbar-nav ml-auto">
            {categories.map(category => (
              <li className="nav-item dropdown" key={category.id}>
                <Link
                  className="nav-link dropdown-toggle lift ml-auto"
                  id="navbarLandings"
                  data-toggle="dropdown"
                  to={`/categoria/${category.id}`}
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {category.nome}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
