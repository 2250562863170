import React from 'react';
import { Link } from 'react-router-dom';
import Lost from '../../assets/img/illustrations/lost.svg';
import AOS from 'aos';

const NotFound: React.FC = () => {
  AOS.init();

  return (
    <section className="section-border border-primary">
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center no-gutters min-vh-100">
          <div
            className="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11"
            data-aos="fade-up"
          >
            <img src={Lost} alt="..." className="img-fluid" />
          </div>
          <div
            className="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11"
            data-aos="fade-up"
          >
            <h1 className="display-3 font-weight-bold text-center">Uh Oh.</h1>

            <p className="mb-5 text-center text-muted">
              Parece que você se perdeu, essa página não existe
            </p>

            <div className="text-center">
              <Link className="btn btn-primary" to="/">
                Voltar à segurança
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
