import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';

import './assets/css/theme.min.css';
import './assets/libs/@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import './assets/libs/choices.js/public/assets/styles/choices.min.css';
import './assets/libs/flickity-fade/flickity-fade.css';
import './assets/libs/flickity/dist/flickity.min.css';
import 'aos/dist/aos.css';
import './assets/libs/highlightjs/styles/vs2015.css';
import './assets/libs/jarallax/dist/jarallax.css';
import './assets/libs/quill/dist/quill.core.css';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default App;
