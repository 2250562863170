import React, { useMemo, useState, useEffect } from 'react';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import api from '../../services/api';
import AOS from 'aos';

import MainImage from '../../assets/img/covers/cover-13.jpg';
import Footer from '../../components/Footer';

interface IPost {
  id: number;
  titulo: string;
  conteudo: string;
  descricao: string;
  created_at: string;
  created_by: {
    id: number;
    firstname: string;
    lastname: string;
  };
  imagem: {
    url: string;
  };
}

const Home: React.FC = () => {
  AOS.init();

  const [posts, setPosts] = useState<IPost[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get('/posts').then(response => {
      setPosts(response.data);
    });
  }, []);

  const latestPost = useMemo(() => {
    const postsId = posts.map(post => post.id);

    return posts.find(findPost => findPost.id === Math.max(...postsId));
  }, [posts]);

  const secondPost = useMemo(() => {
    const postsId = posts.map(post => post.id);

    const latest = posts.find(findPost => findPost.id === Math.max(...postsId));

    const findIndex = postsId.findIndex(
      findService => findService === latest?.id,
    );

    postsId.splice(findIndex, 1);

    return posts.find(findPost => findPost.id === Math.max(...postsId));
  }, [posts]);

  const thirdPost = useMemo(() => {
    const postsId = posts.map(post => post.id);

    const latest = posts.find(findPost => findPost.id === Math.max(...postsId));

    const findIndex = postsId.findIndex(
      findService => findService === latest?.id,
    );

    postsId.splice(findIndex, 1);

    const second = posts.find(findPost => findPost.id === Math.max(...postsId));

    const findSecondIndex = postsId.findIndex(
      findService => findService === second?.id,
    );

    postsId.splice(findSecondIndex, 1);

    return posts.find(findPost => findPost.id === Math.max(...postsId));
  }, [posts]);

  setTimeout(() => setLoading(false), 600);

  return (
    <>
      <Header />
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
          }}
        >
          <div className="spinner-border text-primary  " role="status" />
        </div>
      )}
      {!loading && (
        <>
          <section
            data-jarallax
            data-speed=".8"
            className="py-10 py-md-14 overlay overlay-black overlay-60 bg-cover jarallax"
            style={{ backgroundImage: `url(${MainImage})` }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-7 text-center">
                  <h1
                    className="display-2 font-weight-bold text-white"
                    data-aos="fade-up"
                  >
                    Finance Cores
                  </h1>

                  <p className="lead mb-0 text-white-75" data-aos="fade-up">
                    Leia o nosso blog e fique por dentro das dicas e conselhos
                    finaneceiros que vai mudar sua vida
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="position-relative">
            <div className="shape shape-bottom shape-fluid-x svg-shim text-light">
              <svg
                viewBox="0 0 2880 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
          {/* <section className="mt-n6"> */}
          {/*  <div className="container"> */}
          {/*    <div className="row"> */}
          {/*      <div className="col-12"> */}
          {/*        <form className="rounded shadow mb-4"> */}
          {/*          <div className="input-group input-group-lg"> */}
          {/*            <div className="input-group-prepend"> */}
          {/*              <span className="input-group-text border-0 pr-1"> */}
          {/*                <i className="fe fe-search" /> */}
          {/*              </span> */}
          {/*            </div> */}

          {/*            <input */}
          {/*              type="text" */}
          {/*              className="form-control border-0 px-1" */}
          {/*              aria-label="Search our blog..." */}
          {/*              placeholder="Search our blog..." */}
          {/*            /> */}

          {/*            <div className="input-group-append"> */}
          {/*              <span className="input-group-text border-0 py-0 pl-1 pr-3"> */}
          {/*                <a */}
          {/*                  className="btn btn-sm btn-primary" */}
          {/*                  href="blog-search.html" */}
          {/*                > */}
          {/*                  Search */}
          {/*                </a> */}
          {/*              </span> */}
          {/*            </div> */}
          {/*          </div> */}
          {/*        </form> */}
          {/*      </div> */}
          {/*    </div> */}
          {/*  </div> */}
          {/* </section> */}
          {latestPost && (
            <section className="pt-7 pt-md-10" data-aos="fade-up">
              <Link
                to={`/post/${latestPost.id}`}
                style={{ textDecoration: 'none' }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                        <div className="row no-gutters">
                          <div className="col-12">
                            <span className="badge badge-pill badge-light badge-float badge-float-inside">
                              <span className="h6 text-uppercase">
                                Último post
                              </span>
                            </span>
                          </div>
                          <Link
                            className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                            style={{
                              backgroundImage: `url(https://blogapi.financecores.com${latestPost.imagem.url})`,
                            }}
                            to={`/post/${latestPost.id}`}
                          >
                            <div className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                              <svg
                                viewBox="0 0 112 690"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </Link>
                          <div className="col-12 col-md-6 order-md-1">
                            <a className="card-body" href="#!">
                              <h3>{latestPost.titulo}</h3>

                              <p className="mb-0 text-muted">
                                {latestPost.descricao}
                              </p>
                            </a>

                            <a className="card-meta" href="#!">
                              <hr className="card-meta-divider" />

                              <div className="avatar avatar-sm mr-2">
                                <img
                                  src="assets/img/avatars/avatar-1.jpg"
                                  alt="..."
                                  className="avatar-img rounded-circle"
                                />
                              </div>

                              <h6 className="text-uppercase text-muted mr-2 mb-0">
                                {latestPost.created_by.firstname}{' '}
                                {latestPost.created_by.lastname}
                              </h6>

                              <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                <time dateTime="2019-05-02">
                                  {format(
                                    parseISO(latestPost.created_at),
                                    "dd 'de' MMMM 'de' yyyy",
                                    {
                                      locale: ptBr,
                                    },
                                  )}
                                </time>
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </section>
          )}
          <section className="pt-7 pt-md-10">
            <div className="container">
              <div className="row align-items-center mb-5">
                <div className="col-12 col-md">
                  <h3 className="mb-0">Mais recentes</h3>

                  <p className="mb-0 text-muted">
                    Aqui, nossas mais novas publicações
                  </p>
                </div>
                <div className="col-12 col-md-auto">
                  <Link
                    to="/posts"
                    className="btn btn-sm btn-outline-gray-300 d-none d-md-inline"
                  >
                    Veja tudo
                  </Link>
                </div>
              </div>
              <div className="row">
                {latestPost && (
                  <div className="col-12 col-md-6 col-lg-4 d-flex">
                    <Link
                      to={`/post/${latestPost.id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <div className="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg">
                        <a className="card-img-top" href="#!">
                          <img
                            src={`https://blogapi.financecores.com${latestPost.imagem.url}`}
                            alt="..."
                            className="card-img-top"
                          />

                          <div className="position-relative">
                            <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                              <svg
                                viewBox="0 0 2880 480"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                        </a>

                        <a className="card-body" href="#!">
                          <h3>{latestPost.titulo}</h3>

                          <p className="mb-0 text-muted">
                            {latestPost.descricao}
                          </p>
                        </a>

                        <a className="card-meta mt-auto" href="#!">
                          <hr className="card-meta-divider" />

                          <div className="avatar avatar-sm mr-2">
                            <img
                              src="assets/img/avatars/avatar-1.jpg"
                              alt="..."
                              className="avatar-img rounded-circle"
                            />
                          </div>

                          <h6 className="text-uppercase text-muted mr-2 mb-0">
                            {latestPost.created_by.firstname}
                          </h6>

                          <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                            <time dateTime="2019-05-02">
                              {format(
                                parseISO(latestPost.created_at),
                                "dd 'de' MMMM 'de' yyyy",
                                {
                                  locale: ptBr,
                                },
                              )}
                            </time>
                          </p>
                        </a>
                      </div>
                    </Link>
                  </div>
                )}

                {secondPost && (
                  <div className="col-12 col-md-6 col-lg-4 d-flex">
                    <Link
                      to={`/post/${secondPost.id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <div className="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg">
                        <a className="card-img-top" href="#!">
                          <img
                            src={`https://blogapi.financecores.com${secondPost.imagem.url}`}
                            alt="..."
                            className="card-img-top"
                          />

                          <div className="position-relative">
                            <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                              <svg
                                viewBox="0 0 2880 480"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                        </a>

                        <a className="card-body" href="#!">
                          <h3>{secondPost.titulo}</h3>

                          <p className="mb-0 text-muted">
                            {secondPost.descricao}
                          </p>
                        </a>

                        <a className="card-meta mt-auto" href="#!">
                          <hr className="card-meta-divider" />

                          <div className="avatar avatar-sm mr-2">
                            <img
                              src="assets/img/avatars/avatar-1.jpg"
                              alt="..."
                              className="avatar-img rounded-circle"
                            />
                          </div>

                          <h6 className="text-uppercase text-muted mr-2 mb-0">
                            {secondPost.created_by.firstname}
                          </h6>

                          <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                            <time dateTime="2019-05-02">
                              {format(
                                parseISO(secondPost.created_at),
                                "dd 'de' MMMM 'de' yyyy",
                                {
                                  locale: ptBr,
                                },
                              )}
                            </time>
                          </p>
                        </a>
                      </div>
                    </Link>
                  </div>
                )}

                {thirdPost && (
                  <div className="col-12 col-md-6 col-lg-4 d-flex">
                    <Link
                      to={`/post/${thirdPost.id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <div className="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg">
                        <a className="card-img-top" href="#!">
                          <img
                            src={`https://blogapi.financecores.com${thirdPost.imagem.url}`}
                            alt="..."
                            className="card-img-top"
                          />

                          <div className="position-relative">
                            <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                              <svg
                                viewBox="0 0 2880 480"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                        </a>

                        <a className="card-body" href="#!">
                          <h3>{thirdPost.titulo}</h3>

                          <p className="mb-0 text-muted">
                            {thirdPost.descricao}
                          </p>
                        </a>

                        <a className="card-meta mt-auto" href="#!">
                          <hr className="card-meta-divider" />

                          <div className="avatar avatar-sm mr-2">
                            <img
                              src="assets/img/avatars/avatar-1.jpg"
                              alt="..."
                              className="avatar-img rounded-circle"
                            />
                          </div>

                          <h6 className="text-uppercase text-muted mr-2 mb-0">
                            {thirdPost.created_by.firstname}
                          </h6>

                          <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                            <time dateTime="2019-05-02">
                              {format(
                                parseISO(thirdPost.created_at),
                                "dd 'de' MMMM 'de' yyyy",
                                {
                                  locale: ptBr,
                                },
                              )}
                            </time>
                          </p>
                        </a>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </section>
          {/* <section className="pt-7 pt-md-10"> */}
          {/*  <div className="container"> */}
          {/*    <div className="row"> */}
          {/*      <div className="col-12"> */}
          {/*        <h3 className="mb-0">Mais populares</h3> */}

          {/*        <p className="mb-5 text-muted"> */}
          {/*          Fique ligado nos nossos posts mais acessados */}
          {/*        </p> */}
          {/*      </div> */}
          {/*    </div> */}
          {/*    <div className="row"> */}
          {/*      <div className="col-12 col-md-6 d-flex"> */}
          {/*        <div className="card mb-6 shadow-light-lg lift lift-lg"> */}
          {/*          <a className="card-img-top" href="#!"> */}
          {/*            <img */}
          {/*              src="assets/img/photos/photo-28.jpg" */}
          {/*              alt="..." */}
          {/*              className="card-img-top" */}
          {/*            /> */}

          {/*            <div className="position-relative"> */}
          {/*              <div className="shape shape-bottom shape-fluid-x svg-shim text-white"> */}
          {/*                <svg */}
          {/*                  viewBox="0 0 2880 480" */}
          {/*                  fill="none" */}
          {/*                  xmlns="http://www.w3.org/2000/svg" */}
          {/*                > */}
          {/*                  <path */}
          {/*                    fillRule="evenodd" */}
          {/*                    clipRule="evenodd" */}
          {/*                    d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" */}
          {/*                    fill="currentColor" */}
          {/*                  /> */}
          {/*                </svg> */}
          {/*              </div> */}
          {/*            </div> */}
          {/*          </a> */}

          {/*          <a className="card-body" href="#!"> */}
          {/*            <h3>Greece Is Having A Tech Renaissance.</h3> */}

          {/*            <p className="mb-0 text-muted"> */}
          {/*              Lorem ipsum dolor sit amet, consectetur adipiscing elit. */}
          {/*              Duis nec condimentum quam. Fusce pellentesque faucibus */}
          {/*              lorem at viverra. Integer at feugiat odio. In placerat */}
          {/*              euismod risus proin erat purus. */}
          {/*            </p> */}
          {/*          </a> */}

          {/*          <a className="card-meta mt-auto" href="#!"> */}
          {/*            <hr className="card-meta-divider" /> */}

          {/*            <div className="avatar avatar-sm mr-2"> */}
          {/*              <img */}
          {/*                src="assets/img/avatars/avatar-1.jpg" */}
          {/*                alt="..." */}
          {/*                className="avatar-img rounded-circle" */}
          {/*              /> */}
          {/*            </div> */}

          {/*            <h6 className="text-uppercase text-muted mr-2 mb-0"> */}
          {/*              Ab Hadley */}
          {/*            </h6> */}

          {/*            <p className="h6 text-uppercase text-muted mb-0 ml-auto"> */}
          {/*              <time dateTime="2019-05-02">May 02</time> */}
          {/*            </p> */}
          {/*          </a> */}
          {/*        </div> */}
          {/*      </div> */}
          {/*      <div className="col-12 col-md-6 d-flex"> */}
          {/*        <div className="card mb-6 shadow-light-lg lift lift-lg"> */}
          {/*          <a className="card-img-top" href="#!"> */}
          {/*            <img */}
          {/*              src="assets/img/photos/photo-29.jpg" */}
          {/*              alt="..." */}
          {/*              className="card-img-top" */}
          {/*            /> */}

          {/*            <div className="position-relative"> */}
          {/*              <div className="shape shape-bottom shape-fluid-x svg-shim text-white"> */}
          {/*                <svg */}
          {/*                  viewBox="0 0 2880 480" */}
          {/*                  fill="none" */}
          {/*                  xmlns="http://www.w3.org/2000/svg" */}
          {/*                > */}
          {/*                  <path */}
          {/*                    fillRule="evenodd" */}
          {/*                    clipRule="evenodd" */}
          {/*                    d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" */}
          {/*                    fill="currentColor" */}
          {/*                  /> */}
          {/*                </svg> */}
          {/*              </div> */}
          {/*            </div> */}
          {/*          </a> */}

          {/*          <a className="card-body" href="#!"> */}
          {/*            <h3>Is Failing Actually Bad for Business?</h3> */}

          {/*            <p className="mb-0 text-muted"> */}
          {/*              Lorem ipsum dolor sit amet, consectetur adipiscing elit. */}
          {/*              Duis nec condimentum quam. Fusce pellentesque faucibus */}
          {/*              lorem at viverra. Integer at feugiat odio. In placerat */}
          {/*              euismod risus proin erat purus. */}
          {/*            </p> */}
          {/*          </a> */}

          {/*          <a className="card-meta mt-auto" href="#!"> */}
          {/*            <hr className="card-meta-divider" /> */}

          {/*            <div className="avatar avatar-sm mr-2"> */}
          {/*              <img */}
          {/*                src="assets/img/avatars/avatar-2.jpg" */}
          {/*                alt="..." */}
          {/*                className="avatar-img rounded-circle" */}
          {/*              /> */}
          {/*            </div> */}

          {/*            <h6 className="text-uppercase text-muted mr-2 mb-0"> */}
          {/*              Adolfo Hess */}
          {/*            </h6> */}

          {/*            <p className="h6 text-uppercase text-muted mb-0 ml-auto"> */}
          {/*              <time dateTime="2019-05-02">May 02</time> */}
          {/*            </p> */}
          {/*          </a> */}
          {/*        </div> */}
          {/*      </div> */}
          {/*      <div className="col-12 col-md-6 col-lg-4 d-flex"> */}
          {/*        <div className="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg"> */}
          {/*          <a className="card-img-top" href="#!"> */}
          {/*            <img */}
          {/*              src="assets/img/photos/photo-6.jpg" */}
          {/*              alt="..." */}
          {/*              className="card-img-top" */}
          {/*            /> */}

          {/*            <div className="position-relative"> */}
          {/*              <div className="shape shape-bottom shape-fluid-x svg-shim text-white"> */}
          {/*                <svg */}
          {/*                  viewBox="0 0 2880 480" */}
          {/*                  fill="none" */}
          {/*                  xmlns="http://www.w3.org/2000/svg" */}
          {/*                > */}
          {/*                  <path */}
          {/*                    fillRule="evenodd" */}
          {/*                    clipRule="evenodd" */}
          {/*                    d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" */}
          {/*                    fill="currentColor" */}
          {/*                  /> */}
          {/*                </svg> */}
          {/*              </div> */}
          {/*            </div> */}
          {/*          </a> */}

          {/*          <a className="card-body" href="#!"> */}
          {/*            <h3>Landkit is Moving to an Office In Austin, TX</h3> */}

          {/*            <p className="mb-0 text-muted"> */}
          {/*              Lorem ipsum dolor sit amet, consectetur adipiscing elit. */}
          {/*              Duis nec condimentum quam. */}
          {/*            </p> */}
          {/*          </a> */}

          {/*          <a className="card-meta mt-auto" href="#!"> */}
          {/*            <hr className="card-meta-divider" /> */}

          {/*            <div className="avatar avatar-sm mr-2"> */}
          {/*              <img */}
          {/*                src="assets/img/avatars/avatar-1.jpg" */}
          {/*                alt="..." */}
          {/*                className="avatar-img rounded-circle" */}
          {/*              /> */}
          {/*            </div> */}

          {/*            <h6 className="text-uppercase text-muted mr-2 mb-0"> */}
          {/*              Ab Hadley */}
          {/*            </h6> */}

          {/*            <p className="h6 text-uppercase text-muted mb-0 ml-auto"> */}
          {/*              <time dateTime="2019-05-02">May 02</time> */}
          {/*            </p> */}
          {/*          </a> */}
          {/*        </div> */}
          {/*      </div> */}
          {/*      <div className="col-12 col-md-6 col-lg-4 d-flex"> */}
          {/*        <div className="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg"> */}
          {/*          <a className="card-img-top" href="#!"> */}
          {/*            <img */}
          {/*              src="assets/img/photos/photo-4.jpg" */}
          {/*              alt="..." */}
          {/*              className="card-img-top" */}
          {/*            /> */}

          {/*            <div className="position-relative"> */}
          {/*              <div className="shape shape-bottom shape-fluid-x svg-shim text-white"> */}
          {/*                <svg */}
          {/*                  viewBox="0 0 2880 480" */}
          {/*                  fill="none" */}
          {/*                  xmlns="http://www.w3.org/2000/svg" */}
          {/*                > */}
          {/*                  <path */}
          {/*                    fillRule="evenodd" */}
          {/*                    clipRule="evenodd" */}
          {/*                    d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" */}
          {/*                    fill="currentColor" */}
          {/*                  /> */}
          {/*                </svg> */}
          {/*              </div> */}
          {/*            </div> */}
          {/*          </a> */}

          {/*          <a className="card-body" href="#!"> */}
          {/*            <h3>Are Hot Desks Great for Digital Nomads?</h3> */}

          {/*            <p className="mb-0 text-muted"> */}
          {/*              Lorem ipsum dolor sit amet, consectetur adipiscing elit. */}
          {/*              Duis nec condimentum quam. */}
          {/*            </p> */}
          {/*          </a> */}

          {/*          <a className="card-meta mt-auto" href="#!"> */}
          {/*            <hr className="card-meta-divider" /> */}

          {/*            <div className="avatar avatar-sm mr-2"> */}
          {/*              <img */}
          {/*                src="assets/img/avatars/avatar-2.jpg" */}
          {/*                alt="..." */}
          {/*                className="avatar-img rounded-circle" */}
          {/*              /> */}
          {/*            </div> */}

          {/*            <h6 className="text-uppercase text-muted mr-2 mb-0"> */}
          {/*              Adolfo Hess */}
          {/*            </h6> */}

          {/*            <p className="h6 text-uppercase text-muted mb-0 ml-auto"> */}
          {/*              <time dateTime="2019-05-02">May 02</time> */}
          {/*            </p> */}
          {/*          </a> */}
          {/*        </div> */}
          {/*      </div> */}
          {/*      <div className="col-12 col-md-6 col-lg-4 d-flex"> */}
          {/*        <div className="card mb-6 mb-md-0 shadow-light-lg lift lift-lg"> */}
          {/*          <a className="card-img-top" href="#!"> */}
          {/*            <img */}
          {/*              src="assets/img/photos/photo-5.jpg" */}
          {/*              alt="..." */}
          {/*              className="card-img-top" */}
          {/*            /> */}

          {/*            <div className="position-relative"> */}
          {/*              <div className="shape shape-bottom shape-fluid-x svg-shim text-white"> */}
          {/*                <svg */}
          {/*                  viewBox="0 0 2880 480" */}
          {/*                  fill="none" */}
          {/*                  xmlns="http://www.w3.org/2000/svg" */}
          {/*                > */}
          {/*                  <path */}
          {/*                    fillRule="evenodd" */}
          {/*                    clipRule="evenodd" */}
          {/*                    d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" */}
          {/*                    fill="currentColor" */}
          {/*                  /> */}
          {/*                </svg> */}
          {/*              </div> */}
          {/*            </div> */}
          {/*          </a> */}

          {/*          <a className="card-body" href="#!"> */}
          {/*            <h3>Coffee Can Keep You Going, But At What Cost?</h3> */}

          {/*            <p className="mb-0 text-muted"> */}
          {/*              Lorem ipsum dolor sit amet, consectetur adipiscing elit. */}
          {/*              Duis nec condimentum quam. */}
          {/*            </p> */}
          {/*          </a> */}

          {/*          <a className="card-meta mt-auto" href="#!"> */}
          {/*            <hr className="card-meta-divider" /> */}

          {/*            <div className="avatar avatar-sm mr-2"> */}
          {/*              <img */}
          {/*                src="assets/img/avatars/avatar-1.jpg" */}
          {/*                alt="..." */}
          {/*                className="avatar-img rounded-circle" */}
          {/*              /> */}
          {/*            </div> */}

          {/*            <h6 className="text-uppercase text-muted mr-2 mb-0"> */}
          {/*              Ab Hadley */}
          {/*            </h6> */}

          {/*            <p className="h6 text-uppercase text-muted mb-0 ml-auto"> */}
          {/*              <time dateTime="2019-05-02">May 02</time> */}
          {/*            </p> */}
          {/*          </a> */}
          {/*        </div> */}
          {/*      </div> */}
          {/*      <div className="col-12 col-md-6 col-lg-4 d-flex"> */}
          {/*        <div className="card d-lg-none shadow-light-lg lift lift-lg"> */}
          {/*          <a className="card-img-top" href="#!"> */}
          {/*            <img */}
          {/*              src="assets/img/photos/photo-27.jpg" */}
          {/*              alt="..." */}
          {/*              className="card-img-top" */}
          {/*            /> */}

          {/*            <div className="position-relative"> */}
          {/*              <div className="shape shape-bottom shape-fluid-x svg-shim text-white"> */}
          {/*                <svg */}
          {/*                  viewBox="0 0 2880 480" */}
          {/*                  fill="none" */}
          {/*                  xmlns="http://www.w3.org/2000/svg" */}
          {/*                > */}
          {/*                  <path */}
          {/*                    fillRule="evenodd" */}
          {/*                    clipRule="evenodd" */}
          {/*                    d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" */}
          {/*                    fill="currentColor" */}
          {/*                  /> */}
          {/*                </svg> */}
          {/*              </div> */}
          {/*            </div> */}
          {/*          </a> */}

          {/*          <a className="card-body" href="#!"> */}
          {/*            <h3>Travel Can Keep You Creatively Inspired.</h3> */}

          {/*            <p className="mb-0 text-muted"> */}
          {/*              Lorem ipsum dolor sit amet, consectetur adipiscing elit. */}
          {/*              Duis nec condimentum quam. */}
          {/*            </p> */}
          {/*          </a> */}

          {/*          <a className="card-meta mt-auto" href="#!"> */}
          {/*            <hr className="card-meta-divider" /> */}

          {/*            <div className="avatar avatar-sm mr-2"> */}
          {/*              <img */}
          {/*                src="assets/img/avatars/avatar-1.jpg" */}
          {/*                alt="..." */}
          {/*                className="avatar-img rounded-circle" */}
          {/*              /> */}
          {/*            </div> */}

          {/*            <h6 className="text-uppercase text-muted mr-2 mb-0"> */}
          {/*              Ab Hadley */}
          {/*            </h6> */}

          {/*            <p className="h6 text-uppercase text-muted mb-0 ml-auto"> */}
          {/*              <time dateTime="2019-05-02">May 02</time> */}
          {/*            </p> */}
          {/*          </a> */}
          {/*        </div> */}
          {/*      </div> */}
          {/*    </div> */}
          {/*  </div> */}
          {/* </section> */}
        </>
      )}
      <Footer />
    </>
  );
};

export default Home;
