import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import FlatList from 'flatlist-react';
import { Link, useRouteMatch } from 'react-router-dom';
import api from '../../services/api';
import { format, parseISO } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import AOS from 'aos';
import Footer from '../../components/Footer';
import NotFound from '../NotFound';

interface IParams {
  category: string;
}

interface ICategory {
  id: number;
  nome: string;
  posts: {
    id: number;
    titulo: string;
    descricao: string;
    created_at: string;
    imagem: {
      url: string;
    };
    created_by: {
      id: number;
      firstname: string;
      lastname: string;
    };
  };
}

interface IPost {
  id: number;
  titulo: string;
  descricao: string;
  created_at: string;
  imagem: {
    url: string;
  };
  created_by: {
    id: number;
    firstname: string;
    lastname: string;
  };
}

const CategoryDetail: React.FC = () => {
  AOS.init();

  const { params } = useRouteMatch<IParams>();

  const [category, setCategory] = useState<ICategory>();
  // const [posts, setPosts] = useState<[]>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get(`/categories/${params.category}`).then(response => {
      setCategory(response.data);
    });
  }, [params.category]);

  setTimeout(() => setLoading(false), 600);

  return (
    <>
      <Header />

      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
          }}
        >
          <div className="spinner-border text-primary  " role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {category && !loading && (
        <>
          <section className="py-8 py-md-11 border-bottom">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-8 text-center">
                  <h1
                    className="display-1 font-weight-bold"
                    style={{ fontSize: 53 }}
                  >
                    {category.nome}
                  </h1>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <FlatList
                  key={category.posts.id}
                  list={category.posts}
                  renderItem={(item: IPost) => (
                    <div
                      className="col-12 col-md-6 col-lg-4 d-flex"
                      data-aos="fade-up"
                    >
                      <Link
                        style={{ textDecoration: 'none' }}
                        to={`/post/${item.id}`}
                      >
                        <div className="card mb-6 shadow-light-lg lift lift-lg">
                          <a className="card-img-top" href="#!">
                            <img
                              src={`https://blogapi.financecores.com${item.imagem.url}`}
                              alt="..."
                              className="card-img-top"
                            />

                            <div className="position-relative">
                              <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                                <svg
                                  viewBox="0 0 2880 480"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </div>
                          </a>

                          <a className="card-body" href="#!">
                            <h3>{item.titulo}</h3>

                            <p className="mb-0 text-muted">{item.descricao}</p>
                          </a>

                          <a className="card-meta mt-auto" href="#!">
                            <hr className="card-meta-divider" />

                            <div className="avatar avatar-sm mr-2">
                              <img
                                src="assets/img/avatars/avatar-1.jpg"
                                alt="..."
                                className="avatar-img rounded-circle"
                              />
                            </div>

                            <h6 className="text-uppercase text-muted mr-2 mb-0">
                              {item.created_by.firstname}{' '}
                              {item.created_by.lastname}
                            </h6>

                            <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                              <time dateTime="2019-05-02">
                                {format(
                                  parseISO(item.created_at),
                                  "dd 'de' MMMM 'de' yyyy",
                                  {
                                    locale: ptBr,
                                  },
                                )}
                              </time>
                            </p>
                          </a>
                        </div>
                      </Link>
                    </div>
                  )}
                />
              </div>
            </div>
          </section>
        </>
      )}
      {!loading && !category && <NotFound />}

      <Footer />
    </>
  );
};

export default CategoryDetail;
